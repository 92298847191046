<script setup>
import { base64ToBlob } from "../utils/dataUtils";
import c3api from "../c3api";
const props = defineProps({
  inboundOrder: {
    type: Object,
    required: false,
  },
});
const navItems = (id) => {
  return [
    { text: "Details", icon: "mdi-file", to: `/inbound-orders/${id}` },
    {
      text: "Documents",
      icon: "mdi-folder",
      to: `/inbound-orders/${id}/documents`,
    },
    {
      text: "History",
      icon: "mdi-history",
      to: `/inbound-orders/${id}/history`,
    },
    { text: "Billables", icon: "mdi-cash" },
    { 
      text: "Appointment",
      icon: "mdi-calendar-clock",
      to: `/inbound-orders/${id}/appointments`
    },
    {
      text: "Inventory Transactions",
      icon: "mdi-list-box",
      to: `/inbound-orders/${id}/inventory-transactions`,
    },
    {
      text: "Lp Transactions",
      icon: "mdi-list-box",
      to: `/inbound-orders/${id}/lp-transactions`,
    },
    {
      text: "Pallets",
      icon: "mdi-shipping-pallet",
      to: `/inbound-orders/${id}/pallets`,
    },
  ];
};

const printItems = (id) => {
  return [
    { text: "Receiving Slip", icon: "mdi-printer" },
    { text: "Put Slip", icon: "mdi-printer" },
    { text: "Pallet Tags", icon: "mdi-printer" },
    { text: "Product Labels", icon: "mdi-printer", click: () => downloadPDF(id) },
  ];
};

const downloadPDF = async (order) => {
  try {
    const response = await c3api.get(`/inbound_orders/${order.id}/product_labels`);
    const { data } = response.data;
    // Convert Base64 to Blob
    const pdfBlob = base64ToBlob(data, "application/pdf");

    // Create a Blob URL
    const blobUrl = URL.createObjectURL(pdfBlob);

    // Create a download link and click it
    const link = document.createElement("a");
    link.href = blobUrl;
    const epochTimestamp = Date.now();
    link.download = `IN-${order.id}-product-labels-${epochTimestamp}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Failed to download PDF:", error);
  }
};
</script>

<template>
  <div class="d-flex ml-4">
    <div class="w-25">
      <v-card class="mx-auto pa-2 w-100">
        <div class="text-center" v-if="!inboundOrder">
          <v-progress-circular
            :width="8"
            :size="50"
            color="primary"
            class="mb-16"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-list v-if="props.inboundOrder">
          <v-list-subheader>INBOUND ORDER #IN-{{ inboundOrder.id }}</v-list-subheader>

          <v-list-item
            v-if="inboundOrder"
            v-for="(item, i) in navItems(inboundOrder.id)"
            exact="true"
            :key="i"
            :value="item"
            color="primary"
            rounded="xl"
            :to="item.to"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>

      <v-card class="mx-auto pa-2 w-100 mt-4">
        <div class="text-center" v-if="!inboundOrder">
          <v-progress-circular
            :width="8"
            :size="50"
            color="primary"
            class="mb-16"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-list v-if="props.inboundOrder">
          <v-list-subheader>PRINT</v-list-subheader>

          <v-list-item
            v-if="inboundOrder"
            v-for="(item, i) in printItems(inboundOrder)"
            :key="i"
            :value="item"
            color="primary"
            rounded="xl"
            @click="item.click ? item.click() : null"
          >
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>

            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
    <div class="w-100">
      <slot />
    </div>
  </div>
</template>
